if ($('.section-lc-mod-38-blog').length) {

    // Just inital calculation of already present DOMdata from CMS.
    blogColsSetSize();

    $(window).resize(function (e) {
        blogColsSetSize();
        e.stopPropagation();
    });

    
    var dataSiteURL = $('.section-lc-mod-38-blog .lcblog-columns').attr('data-url');

    $('.section-lc-mod-38-blog .dropdown-item').bind('touchstart click', function(e){
        var $this = $(this);
        var tagID = $(this).attr('data-tagID');
        console.log('tagID: ' + tagID);

        

        // Set attribute to Load more btn
        $('.section-lc-mod-38-blog #blogloadmore').attr('data-tagID',tagID);
        $('.section-lc-mod-38-blog #blogloadmore').attr('data-nextPage','');

        var dataURL = dataSiteURL + '/theme/includes/block-blog-add-card.cfm?tagID=' + tagID;
        console.log('dataURL: ' + dataURL);
        $.ajax({
            url: dataURL,
            dataType: 'json',
            success: function(data, textStatus, jqXHR) {
                console.log('next: ' + data[0]['nextPage']);
                console.log('content: ' + data[0]['content']);
                if (data[0]['nextPage'] == '') {
                    $('.section-lc-mod-38-blog #blogloadmore').addClass('invisible');
                } else {
                    $('.section-lc-mod-38-blog #blogloadmore').removeClass('invisible');
                    $('.section-lc-mod-38-blog #blogloadmore').attr('data-nextPage',data[0]['nextPage']);
                }
                blogReplacePost(data[0]['content']);

                $this.parents(".dropdown").find('.btn').html($this.text());
                $this.parents(".dropdown").find('.btn').val($this.data('value'));
                $('.dropdown').removeClass('show');

            },
            error: function(jqXHR, textStatus, errorThrown) {
                // error handling
                console.log('error');
            }
        });
        e.stopPropagation();


    });
    
    $('.section-lc-mod-38-blog .tag-button').bind('touchstart click', function(e){
        var $this = $(this);
        var tagID = $(this).attr('data-tagID');
        
        // show the tag btn as active
        $('.section-lc-mod-38-blog .tag-button').removeClass('btn-maries-blue');
        $this.addClass('btn-maries-blue');

        // Set attribute to Load more btn
        $('.section-lc-mod-38-blog #blogloadmore').attr('data-tagID',tagID);
        $('.section-lc-mod-38-blog #blogloadmore').attr('data-nextPage','');

        
        var dataURL = dataSiteURL + '/theme/includes/block-blog-add-card.cfm?tagID=' + tagID;
        console.log('dataURL: ' + dataURL);
        $.ajax({
            url: dataURL,
            dataType: 'json',
            success: function(data, textStatus, jqXHR) {
                console.log('next: ' + data[0]['nextPage']);
                console.log('content: ' + data[0]['content']);
                if (data[0]['nextPage'] == '') {
                    $('.section-lc-mod-38-blog #blogloadmore').addClass('invisible');
                } else {
                    $('.section-lc-mod-38-blog #blogloadmore').removeClass('invisible');
                    $('.section-lc-mod-38-blog #blogloadmore').attr('data-nextPage',data[0]['nextPage']);
                }
                blogReplacePost(data[0]['content']);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                // error handling
                console.log('error');
            }
        });
        e.stopPropagation();
    });


    $('.section-lc-mod-38-blog #blogloadmore').bind('touchstart click', function(e){
        var $this = $(this);
        // Get Next Page
        var nextPage = $(this).attr('data-nextPage');
        console.log('nextPage: ' + nextPage);
        // Get Tag ID
        var tagID = $(this).attr('data-tagID');
        console.log('tagID: ' + tagID);
        // Example data:
        var dataURL = dataSiteURL + '/theme/includes/block-blog-add-card.cfm?nextPage=' + nextPage + '&tagID=' + tagID;
        console.log('dataURL: ' + dataURL);
        $.ajax({
            url: dataURL,
            dataType: 'json',
            success: function(data, textStatus, jqXHR) {
                console.log('success: ' + data[0]['nextPage']);
                if (data[0]['nextPage'] == '') {
                    $this.addClass('invisible');
                } else {
                    $this.removeClass('invisible');
                    $this.attr('data-nextPage',data[0]['nextPage']);
                }
                
                blogLoadPost(data[0]['content']);


            },
            error: function(jqXHR, textStatus, errorThrown) {
                // error handling
                console.log('error');
            }
        });

        e.stopPropagation();
    });


    function blogLoadPost(domData) {
        // Append a lc-mod-38-blog post
        $('section.section-lc-mod-38-blog').find('.lcblog-columns').append(domData);

        // Redadjust height only on desktop.
        if ($('section.section-lc-mod-38-blog').find('.lcblog-columns').css('display') == "flex") {

            // Calculate new col heights and set new odd bottom push-margin
            blogColsSetSize();
        }
    }

    function blogReplacePost(domData) {
        // Append a lc-mod-38-blog post
        $('section.section-lc-mod-38-blog').find('.lcblog-columns').html(domData);
        // Redadjust height only on desktop.
        if ($('section.section-lc-mod-38-blog').find('.lcblog-columns').css('display') == "flex" && domData != '') {
            // Calculate new col heights and set new odd bottom push-margin
            blogColsSetSize();
        } else if (domData == '' ){
            $('section.section-lc-mod-38-blog').find('.lcblog-columns').css('height', 'auto');
            $('section.section-lc-mod-38-blog').find('.lcblog-columns').find('.card:nth-last-child(2):nth-child(odd)').css('margin-bottom', '1.5rem');
            $('section.section-lc-mod-38-blog').find('.lcblog-columns').html('<div class="col-12 text-center">no data</div>');
        }
    }


    function blogColsSetSize() {
        console.log('blogColsSetSize');
        // Only run on desktop, check for flex to detect.
        if ($('section.section-lc-mod-38-blog').find('.lcblog-columns').css('display') == "flex") {

            var blogOddColsHeight = 0;
            var blogEvenColsHeight = 0;

            // Reset bottom push-margin
            $('section.section-lc-mod-38-blog').find('.lcblog-columns').find('.card').css('margin-bottom', '2rem');

            // Get odd col height
            $('section.section-lc-mod-38-blog').find('.lcblog-columns').find('.card:nth-child(odd)').each(function () {
                blogOddColsHeight += $(this).outerHeight(true);
            });

            // Get even col height
            $('section.section-lc-mod-38-blog').find('.lcblog-columns').find('.card:nth-child(even)').each(function () {
                blogEvenColsHeight += $(this).outerHeight(true);
            });


            // Compare height of even and odd columns (add some px to avoid false detects)
            if (blogOddColsHeight < (blogEvenColsHeight)) {
                // Bottom push-margin should be the difference between the two columns.
                var marginToSet = blogEvenColsHeight - blogOddColsHeight;

                // if even (2) is highest column, add margin bottom to last element in the odd column.
                // So it pushes the next element. to the next column.
                $('section.section-lc-mod-38-blog').find('.lcblog-columns').find('.card:nth-last-child(2):nth-child(odd)').css('margin-bottom', marginToSet + 'px');
            } else {
                // if odd (1) is highest column, reset margin.
                $('section.section-lc-mod-38-blog').find('.lcblog-columns').find('.card:nth-last-child(2):nth-child(odd)').css('margin-bottom', '2rem');
            }

            // Set height of the whole container to the highest column. Important so that flexbox columns breaks correctly.
            var blogcontainerheight = Math.max(blogOddColsHeight, blogEvenColsHeight);
            $('section.section-lc-mod-38-blog').find('.lcblog-columns').css('height', blogcontainerheight + 'px');
        } else {
            // Mobile, reset height and remove bottom push-margin.
            $('section.section-lc-mod-38-blog').find('.lcblog-columns').css('height', 'auto');
            $('section.section-lc-mod-38-blog').find('.lcblog-columns').find('.card:nth-last-child(2):nth-child(odd)').css('margin-bottom', '2rem');
        }
    }

};/**
 * Init
 */


/**
 * Event handlers
 */


/**
 * Functions
 */


