/**
* Track step function for google analytics.
*/
var marketauto = function (serviceName,step,custid) {

    // If email set
    if(custid != null){
        // If last, order confirmation step - set as customer
        if(step == 'booked'){
            localStorage.setItem('mpsCust',custid);
        }
    }

    // Remove
    if((custid == null) && (step == 'booked')){
        localStorage.removeItem('mpsCust');
    }

    // Check if current customer
    var lCust = localStorage.getItem('mpsCust');

    // Check if email is set and customer
    if(lCust != null){
        markData = {
            "custid": lCust,
            "step": step,
            "service": serviceName
        }

        // Ajax request to mark-api - Contacts proxy, that just contacts underlaying API in Finland.
        var xmlhttp = new XMLHttpRequest();
            xmlhttp.open("POST", "/mark.cfm");
      //  xmlhttp.open("POST", "http://192.168.2.232:14000/lc-maries19.com/subdomains/www/dist//mark.cfm");
        xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xmlhttp.send(JSON.stringify(markData));
    }
}



/**
 * Track step function for google analytics.
 * */
var trackStep = function trackStep(serviceFormID,stepNr,subStepNr,custId) {

    if(subStepNr == ''){
        subStepNr = 0;
    }

    //var currentPath = window.location.pathname;
    var currentPath = '/form/' + serviceFormID;


    var virtualPathWithStep = currentPath;

    if(stepNr != null){
        virtualPathWithStep = virtualPathWithStep + '/' + stepNr;
        if(subStepNr != null){
            virtualPathWithStep = virtualPathWithStep + '/' + subStepNr;
        }
    }
    _gaq.push(['_setAccount', 'UA-684167-22']);
    _gaq.push(['_trackPageview', virtualPathWithStep]);

    marketauto(serviceFormID,stepNr,custId);
};


/**
 * Export Public API
 */
module.exports.trackStep = trackStep;