var globals = require('../../js/globals.js');
// Init
if($('.happypercentage').length) {
    
    var decodeEntities = (function() {
      // this prevents any overhead from creating the object each time
      var element = document.createElement('div');

      function decodeHTMLEntities (str) {
        if(str && typeof str === 'string') {
          // strip script/html tags
          str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
          str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
          element.innerHTML = str;
          str = element.textContent;
          element.textContent = '';
        }
        return str;
      }
      return decodeHTMLEntities;
    })();

    var jsonHiUrl = globals.siteURL + '/theme/includes/block-lequest-data.cfm';
    $.getJSON({
        url: jsonHiUrl,
        success: function(data, textStatus, jqXHR) {
            //console.log('mod 5 data: ' + data);
            $('.happypercentage._1').html(data[0]);
            $('.happypercentage._2').html(data[1]);
            $('.happypercentage._3').html(data[2]);
            $('.happypercentage._4').html(data[3]);
            $('.hi._answerNb').html(data[4]);
            $('.hi._answerNbTot').html(data[5]);
            var trHTML = '';
            $.each(data[6], function (i, item) {
                txt0 = decodeEntities(data[6][i][0]);
                txt1 = decodeEntities(data[6][i][1]);
                txt2 = decodeEntities(data[6][i][2]);
                txt3 = decodeEntities(data[6][i][3]);
                //if (data[6][i][3]) {
                trHTML += '<div class="row comment-row"><div class="col-2 col-sm-1"><img src="https://d33dc73q59hqkx.cloudfront.net/icons/happy' + txt0 + '@2x.png" class="happy-comment-icon" style="height: 60px; width: 60px;"></div>';
                trHTML += '<div class="col-8"><em>' + txt1 + '</em><p>' + txt2 + '</p>'
                if (data[6][i][3]) trHTML += '<div class="comment-answer"><strong>Svar från Maries Puts & Städ:</strong><p><em>' + txt3 + '</em></p></div>';
                // if (data[6][i][4]) trHTML += '<p>response: ' + decodeEntities(data[6][i][4]); + '</p>';
                trHTML += '</div></div>';
              //}
              
            });
            //console.log('trHTML: '+ trHTML);
            //html = $.parseHTML(trHTML);
            $('#hi-comments').html(trHTML);
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.log('error mod 5: ' + textStatus);
        }
    });
}

//# sourceURL=block-module-5.js