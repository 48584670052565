var urlDecide = function urlDecide() {
    /**
     * Default - live site, use as default to avoid errors.
     */
    var mainAjaxUrl = 'https://www.maries.se/';

    //var mainAjaxUrl = 'http://local:4000/maries.se/subdomains/www/dist/'

    /**
     * Staging environments.
     */
    // lc-maries19
    if ( window.location.hostname == 'mariesstage.lcsthlm.co') {
        mainAjaxUrl = 'https://mariesstage.lcsthlm.co/';
    }

    /**
     * Dev environments.
     * Both mv2 and maries.se branches included in this file for easy copying.
     */
    // HP local dev environments
    if ( window.location.hostname == 'local') {
        // Maries live branch
        if( window.location.pathname.indexOf('maries.se') > 0){
         //   mainAjaxUrl = 'http://localhost:3000/maries.se/dist/';
            mainAjaxUrl = 'http://local:4000/maries.se/subdomains/www/dist/';
        }
        // MV2 stage branch
        if( window.location.pathname.indexOf('mv2') > 0){
            mainAjaxUrl = 'http://localhost:3000/lcsthlm.com/subdomains/mv2/dist/';
        }
    }

    // MB local dev environments. 232 or 254
    if ( window.location.hostname == '192.168.2.232') {
        // Maries split branch
        if( window.location.pathname.indexOf('lc-maries19') > 0){
            mainAjaxUrl = 'http://192.168.2.232:14000/lc-maries19.com/subdomains/www/dist/';
        }
        // Maries live branch
        if( window.location.pathname.indexOf('maries.se') > 0){
            mainAjaxUrl = 'http://192.168.2.232:14000/maries.se/dist/';
        }
        // MV2 stage branch
        if( window.location.pathname.indexOf('mv2') > 0){
            mainAjaxUrl = 'http://192.168.2.232:11000/lcsthlm.com/subdomains/mv2/dist/';
        }
    }

    return mainAjaxUrl;
};

var siteIndex = function siteIndex() {
    
    /********************************************/
    /* Default - live site, use as default to avoid errors. */
    /********************************************/
    var siteIndexStr = '';

    /********************************************/
    /* Dev environments */
    /********************************************/
    
    // HP local dev environments
    if ( window.location.hostname == 'local') {
        // Maries live branch
        if( window.location.pathname.indexOf('maries.se') > 0){
            siteIndexStr = 'index.cfm/';
        }
        // MV2 stage branch
        if( window.location.pathname.indexOf('mv2') > 0){
            siteIndexStr = 'index.cfm/';
        }
    }

    // MB local dev environments. 232 or 254
    if ( window.location.hostname == '192.168.2.232') {
        // Maries live branch
        if( window.location.pathname.indexOf('maries.se') > 0){
            siteIndexStr = 'index.cfm/';
        }
        // MV2 stage branch
        if( window.location.pathname.indexOf('mv2') > 0){
            siteIndexStr = 'index.cfm/';
        }
    }

    return siteIndexStr;
};

module.exports = {
    siteURL: urlDecide(),
    siteIndex: siteIndex(),
    loaderCode: '<div class="row"><div class="col-12"><div class="loading"></div></div></div>'
};

