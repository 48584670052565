/**
 * Script for expand/hide next element, used for expand lists and more.
 */

// Init expansion/collapse for next elements in expandlist
$('.section-expandlistfaq').find('.lc-btn-expand-next').next().collapse({
    'toggle':false
});


$('.section-expandlistfaq').find('.lc-btn-expand-next').on( "click", function() {
    if($(this).next().is(":visible")){
        // Hide next
        $(this).next().collapse('hide');
    } else {
        // Hide all
        $(this).closest('.row').find('.lc-btn-expand-next').next().collapse('hide');
        // Show next
        $(this).next().collapse('show');
    }
});
