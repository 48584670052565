// Init
$formCall = $('#form_lc_callme');

// on submit click - Check captcha
$formCall.on('click', '#f_submit', function(e) {
    e.stopImmediatePropagation();

    // Is the form valid?
    if ($formCall.valid()) {
        formCallMeSubmit();
    }
});


// Submit the form
function formCallMeSubmit () {
    // Show & hide
    $formCall.find('.form-wait').show(); // show loader
    // send the form
    var postData = $formCall.serializeArray();
    var dataURL = '/theme/includes/block-form-callme-submit.cfm';
    $.ajax({
        data: postData,
        type: 'post',
        url: dataURL,
        dataType: "json",
        success: function(data, textStatus, jqXHR) {
            $formCall.find('.form-wait').hide();
            switch(data[0]) {
                case 'mailsent':
                    $formCall.find('.row').hide();
                    $formCall.find('.form-answer').show();
                    break;
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            // error handling
            console.log('error');
        }
    });
}


// Validator email complete
jQuery.validator.addMethod("validate_phone",function(value, element) {
//    if(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})+$/.test(value))
    if(/^([0-9_\.\-])+$/.test(value))
    { return true;} else { return false; }
},"Fyll i korrekt telefonnummer.");

// Validator
var validatorCallme = $formCall.validate ({
    rules: {
        f_phone: {required: true, validate_phone: true}
    },
    highlight: function (element) {
        $(element).closest('.field').removeClass('has-success').addClass('has-danger');
    },
    unhighlight: function (element) {
        $(element).closest('.field').removeClass('has-danger');
    },
    errorPlacement: function(error,element) {
        return true;
    }
});





